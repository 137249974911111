const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

// Common
const ACCESS_KEY_ID = process.env.REACT_APP_BASE_URL_LOCAL_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY =
  process.env.REACT_APP_BASE_URL_LOCAL_SECRET_ACCESS_KEY;
const REGION = process.env.REACT_APP_BASE_URL_LOCAL_REGION;

// Local
const API_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL;
const SOCKET_URL_LOCAL = process.env.REACT_APP_ONLINE_USER_URL_LOCAL;
const BUCKET_LOCAL_NAME = process.env.REACT_APP_BASE_URL_LOCAL_BUCKET_NAME;

// Dev
const API_URL_DEV = process.env.REACT_APP_BASE_URL_DEV;
const SOCKET_URL_DEV = process.env.REACT_APP_ONLINE_USER_URL_DEV;
const BUCKET_DEV_NAME = process.env.REACT_APP_BASE_URL_DEV_BUCKET_NAME;

// Prod
const API_URL_PROD = process.env.REACT_APP_BASE_URL_PROD;
const SOCKET_URL_PROD = process.env.REACT_APP_ONLINE_USER_URL_PROD_SOCKET;
const BUCKET_PROD_NAME = process.env.REACT_APP_BASE_URL_PROD_BUCKET;

const config = {
  base: {
    url: API_URL_LOCAL,
  },
  ws: {
    url: SOCKET_URL_LOCAL,
  },
  s3: {
    access_key: ACCESS_KEY_ID,
    secret_key: SECRET_ACCESS_KEY,
    region: REGION,
    bucket: BUCKET_LOCAL_NAME,
  },
};
//CHECK FOR ENVIRONMENT
if (ENVIRONMENT === "prod") {
  config.base.url = API_URL_PROD;
  config.ws.url = SOCKET_URL_PROD;
  config.s3.access_key = ACCESS_KEY_ID;
  config.s3.secret_key = SECRET_ACCESS_KEY;
  config.s3.region = REGION;
  config.s3.bucket = BUCKET_PROD_NAME;
} else if (ENVIRONMENT === "dev") {
  config.base.url = API_URL_DEV;
  config.ws.url = SOCKET_URL_DEV;
  config.s3.access_key = ACCESS_KEY_ID;
  config.s3.secret_key = SECRET_ACCESS_KEY;
  config.s3.region = REGION;
  config.s3.bucket = BUCKET_DEV_NAME;
} else {
  config.base.url = API_URL_LOCAL;
  config.ws.url = SOCKET_URL_LOCAL;
  config.s3.access_key = ACCESS_KEY_ID;
  config.s3.secret_key = SECRET_ACCESS_KEY;
  config.s3.region = REGION;
  config.s3.bucket = BUCKET_LOCAL_NAME;
}

export { config, ENVIRONMENT };
